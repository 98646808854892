x-breadcrumbs[container]
  +preset(3)
  display: flex
  flex-wrap: wrap
  margin: -7px 0 15px

x-breadcrumbs[item]
  display: flex
  align-items: center
  white-space: nowrap
  color: var(--on-primary-dark-50)
  a
    color: var(--on-primary-dark)
    &:hover
      color: var(--on-primary-green)
  figure
    flex: 0 0 auto
    width: 16px
    height: 16px
    color: var(--icon-dark-50)
    margin: 0 1px
  &:last-child
    figure
      display: none
