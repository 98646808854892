import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'x-breadcrumbs',
  styleUrl: 'x-breadcrumbs.sass',
})
export class XBreadcrumbs {
  @Prop() container: boolean;
  @Prop() item: boolean;
  @Prop() href: string;

  render() {
    if (this.container) {
      return (
        <Host>
          <slot></slot>
        </Host>
      );
    } else if (this.item) {
      return (
        <Host>
          {!!this.href ? (
            <a href={this.href}>
              <slot></slot>
            </a>
          ) : (
            <slot></slot>
          )}
          <figure>
            <x-icon glyph="bread-arrow"></x-icon>
          </figure>
        </Host>
      );
    } else {
      return null;
    }
  }
}
